import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../shared/Hero"

const DonateHero = () => {
  const {
    sanityDonatePage: {
      donateHeroBlock: { heroText, heroImage },
    },
  } = useStaticQuery(graphql`
    query {
      sanityDonatePage {
        donateHeroBlock {
          heroText
          heroImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              title
            }
          }
        }
      }
    }
  `)

  return <Hero heroText={heroText} heroImage={heroImage} />
}

export default DonateHero
