import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import DonateHero from "../components/donate/hero";
import DonateIntro from "../components/donate/intro";
import Payment from "../components/donate/payment";
import InfoBlock from "../components/donate/infoBlock";
import ImpactBlock from "../components/shared/ImpactBlock";
import SEO from "../components/shared/Seo";
import DonateButton from "../components/shared/sub/DonateButton";

const DonatePage = () => {
  const { sanityDonatePage } = useStaticQuery(graphql`
    query {
      sanityDonatePage {
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
        cta {
          subText
          mainText
          buttonLink {
            url
            linkText
          }
        }
      }
    }
  `);

  const { url, linkText } = sanityDonatePage.cta.buttonLink;

  return (
    <div>
      <SEO seoData={sanityDonatePage?.seoBlock} slug="donate" />
      <DonateHero />
      <DonateIntro marginOveride="70px auto 10px auto" />
      {/* <DonateButton buttonUrl={url} buttonText={linkText} /> */}
      <Payment requestedPage="donate" />
      <InfoBlock />
      <ImpactBlock />
    </div>
  );
};

export default DonatePage;
